const GB = {
	"Aberdeenshire": "Aberdeenshire",
	"Angus": "Angus",
	"Antrim": "Antrim",
	"Argyll": "Argyll",
	"Armagh": "Armagh",
	"Ayrshire": "Ayrshire",
	"Banffshire": "Banffshire",
	"Bedfordshire": "Bedfordshire",
	"Berkshire": "Berkshire",
	"Berwickshire": "Berwickshire",
	"Bristol": "Bristol",
	"Buckinghamshire": "Buckinghamshire",
	"Bute": "Bute",
	"Caithness": "Caithness",
	"Cambridgeshire": "Cambridgeshire",
	"Cheshire": "Cheshire",
	"City of London": "City of London",
	"Clackmannanshire": "Clackmannanshire",
	"Clwyd": "Clwyd",
	"Cornwall": "Cornwall",
	"Cumbria": "Cumbria",
	"Derbyshire": "Derbyshire",
	"Devon": "Devon",
	"Dorset": "Dorset",
	"Down": "Down",
	"Dumfriesshire": "Dumfriesshire",
	"Dunbartonshire": "Dunbartonshire",
	"Durham": "Durham",
	"Dyfed": "Dyfed",
	"East Lothian": "East Lothian",
	"East Riding of Yorkshire": "East Riding of Yorkshire",
	"East Sussex": "East Sussex",
	"Essex": "Essex",
	"Fermanagh": "Fermanagh",
	"Fife": "Fife",
	"Gloucestershire": "Gloucestershire",
	"Greater London": "Greater London",
	"Greater Manchester": "Greater Manchester",
	"Gwent": "Gwent",
	"Gwynedd": "Gwynedd",
	"Hampshire": "Hampshire",
	"Herefordshire": "Herefordshire",
	"Hertfordshire": "Hertfordshire",
	"Inverness-shire": "Inverness-shire",
	"Isle of Wight": "Isle of Wight",
	"Kent": "Kent",
	"Kincardineshire": "Kincardineshire",
	"Kinross-shire": "Kinross-shire",
	"Kirkcudbrightshire": "Kirkcudbrightshire",
	"Lanarkshire": "Lanarkshire",
	"Lancashire": "Lancashire",
	"Leicestershire": "Leicestershire",
	"Lincolnshire": "Lincolnshire",
	"Londonderry": "Londonderry",
	"Merseyside": "Merseyside",
	"Mid Glamorgan": "Mid Glamorgan",
	"Midlothian": "Midlothian",
	"Moray": "Moray",
	"Nairnshire": "Nairnshire",
	"Norfolk": "Norfolk",
	"North Yorkshire": "North Yorkshire",
	"Northamptonshire": "Northamptonshire",
	"Northumberland": "Northumberland",
	"Nottinghamshire": "Nottinghamshire",
	"Orkney": "Orkney",
	"Oxfordshire": "Oxfordshire",
	"Peeblesshire": "Peeblesshire",
	"Perthshire": "Perthshire",
	"Powys": "Powys",
	"Renfrewshire": "Renfrewshire",
	"Ross and Cromarty": "Ross and Cromarty",
	"Roxburghshire": "Roxburghshire",
	"Rutland": "Rutland",
	"Selkirkshire": "Selkirkshire",
	"Shetland": "Shetland",
	"Shropshire": "Shropshire",
	"Somerset": "Somerset",
	"South Glamorgan": "South Glamorgan",
	"South Yorkshire": "South Yorkshire",
	"Staffordshire": "Staffordshire",
	"Stirlingshire": "Stirlingshire",
	"Suffolk": "Suffolk",
	"Surrey": "Surrey",
	"Sutherland": "Sutherland",
	"Tyne and Wear": "Tyne and Wear",
	"Tyrone": "Tyrone",
	"Warwickshire": "Warwickshire",
	"West Glamorgan": "West Glamorgan",
	"West Lothian": "West Lothian",
	"West Midlands": "West Midlands",
	"West Sussex": "West Sussex",
	"West Yorkshire": "West Yorkshire",
	"Wigtownshire": "Wigtownshire",
	"Wiltshire": "Wiltshire",
	"Worcestershire": "Worcestershire"
}

export default GB;
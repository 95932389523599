export const lt_actions={
    1000:{
            "type": "action",
            "status": 1000,
            "action": "modifyBooking",
            "help": "",
        },
    1001:{
            "type": "action",
            "status": 1001,
            "action": "cancelBooking",
            "help": "",
        },
}
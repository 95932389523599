import { NavLink} from 'react-router-dom';
import { formatDollar } from '../../utilities/utilities';
import irates from '../../data/iprefer';


const Rate = ({data,code,handleRoomRateSelection,roomCode,ratePlan,handleRateDetails,rateDetail,handleLogIn,handleDetails,context}) => {
    let nightlyRate = data.allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax;    
    let name = ratePlan['@attributes']['RatePlanName'];
    let ratePlanID = ratePlan['@attributes'].hasOwnProperty("RatePlanID") ? ratePlan['@attributes']['RatePlanID'] : false;
    const isInclusive = data.totals["@attributes"].AmountBeforeTax === data.totals["@attributes"].AmountAfterTax ? true : false ;
    let action;
    console.log(ratePlan);


    if(irates.includes(code)){
        action=
        <button type='button' onClick={()=>{handleLogIn()}}>
            Log in as I Prefer
        </button>
    }else{
        action=
        <button type='button' className='btn-book' onClick={()=>{handleRoomRateSelection(data,roomCode,code,ratePlan,ratePlanID,true)}}>
            <NavLink to={`/room/${roomCode}/${code}`}>Book Now</NavLink>
        </button>
    }
    return (
        <div 
        id="rate" data-rate={code}
        className={`${(rateDetail.roomCode===roomCode) === true && (rateDetail.ratePlan["@attributes"].RatePlanCode === code) === true ? "active" : ""} ${code === "SUMMERSALE" ? "rate-sale" : ""}`}>
        
        {code === "SUMMERSALE" ? 
            <span class="wdp-ribbon wdp-ribbon-six"><span class="wdp-ribbon-inner-wrap"><span class="wdp-ribbon-border"></span><span class="wdp-ribbon-text">Sale</span></span></span>
        : ""}

        <div className='rate-title'>
            <h4 className='br-10'>{name}</h4>
            {code === "NONREF"? <small>Non-refundable</small>:<small>Free Cancellation</small>}
        </div>
        <div className='flex flex-even flex-y-center'>

            <div id="rate-value" className=''>
                <div className='medium'>From</div>
                <div id="value">
                    {code === "SUMMERSALE"?<><s>{formatDollar(Math.ceil( (nightlyRate * (100 / 75)) ))}</s><br/></> :""}

                    {/* {code === "FLASHSALE"?<><s>{formatDollar(nightlyRate * 1.15)}</s><br/></> :""}
                    {code === "EARLYB"?<><s>{formatDollar(nightlyRate * 1.15)}</s><br/></> :""} */}
                    
                     {formatDollar(nightlyRate)} / night
                </div>
                {!isInclusive?
                    <small>Excluding Taxes &amp; Fees</small>
                :   <small>Taxes Included</small>
                }
            </div>
            <div>
                {action}
                <div className='link' onClick={()=>{
                    handleDetails(data,context,roomCode,ratePlan);
                    // TRACK WITH GA
                    window.dataLayer.push({"event":"seeRateDetails","roomCode":roomCode,"rateCode":ratePlan["@attributes"].RatePlanCode});
                    }} >
                    See details
                </div>
            </div>                
        </div>
    </div>
    )
}

export default Rate
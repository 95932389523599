import React, { PureComponent } from 'react';
import Modal from '../Modal/Modal';
import ReactDOM from 'react-dom';
import  { Navigate  } from 'react-router-dom';
import { CookieMonster } from '../../utilities/utilities';
import { re } from '../../utilities/utilities';
import parse from 'html-react-parser';



 class Handler extends PureComponent {

    state={
        handle:false,
        redirectCountDown:5000
    }

    eventType=(event)=>{

        if(event.type==='error'){
            return this.errors(event);
        }else if(event.type==='success'){
            return this.success(event);
        }else if(event.type==='success-order'){
            return this.success(event);
        }else if(event.type==='success-feedback'){
            return this.success(event);
        }else if(event.type==='clear'){
            return this.clearCookiesAndSession();
        }else if(event.type==='cancelBooking'){
            this.setState({handle:<Modal title={event.error} help={event.help} kill={this.killModal}/>});
        }else if(event.type==='action'){
            return this.actions(event);
        }
    }

    actions=(event)=>{
        if(event.status===1000 && event.action==="modifyBooking"){
            // this.setState({handle:});
        }else if(event.status===1001 && event.action==="cancelBooking"){
            this.setState({handle:<Modal title={event.title} help={event.help} kill={event.kill} isMsg={true}/>});
        }else if(event.status===2000 && event.action==="rateDetails"){
            this.setState({handle:<Modal title={event.error} help={event.help} kill={this.killModal} />});
        }

    }

    success=(event)=>{
        // NEW BOOKING
        if(event.status===200){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            this.setState({handle:<Navigate  to='/booking/success' data={event} />});
            // this.props.history.push('/booking/success');
            
        }else if(event.status===210){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            this.setState({handle:<Navigate  to={`/order/${event.order.order_id}`} data={event} />});
            // this.props.history.push('/booking/success');
            
        }else if(event.status===211){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            this.setState({handle:<Navigate  to={`/thank-you-feedback/`} data={event} />});
            // this.props.history.push('/booking/success');
            
        }else if(event.status===201){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            // console.log("EVENT",event);
            this.setState({handle:<Navigate  to='/booking/found' data={event} />});
            // this.props.history.push('/booking/success');
        }else if(event.status===202){
            // let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            // this.clearCookiesAndSession();
            // this.redirect("/booking/success")
            this.setState({handle:<Navigate  to='/booking/update-success' data={event} />});
            // this.props.history.push('/booking/success');
        }else if(event.status===1002){
            // Cancelled Successfully
            this.clearCookiesAndSession();
            this.setState({handle:<Modal title={this.cleanHelpHTML(event.success)} help={this.cleanHelpHTML(event.help)} kill={re} isMsg={true}/>});
        }
    }

    errors=(event)=>{
        if(event.status===400){
            // NO SESSION
            let modal = <Modal title={event.error} help={event.help} kill={this.killModal} isMsg={true}/>
            this.setState({handle:modal});
            this.clearCookiesAndSession();
            this.redirect("/")
            
        }else if(event.status===401){
            // SESSION EXPIRED IN BROWSER
            let modal = <Modal title={event.error} help={event.help} kill={this.killModal}/>
            this.setState({handle:modal});
            this.clearCookiesAndSession();
            this.redirect("/")
            
            
        }else if(event.status===410){
            // UNABLE TO PROCESS PAYMENT
            // console.log(event);
            let modal = <Modal title={event.error} help={event.help} extraHelp={event.extra_help} kill={this.killModal}/>
            this.setState({handle:modal});

        }else if(event.status===432){
            // PAYMENT_OK_RES_ERROR <----------------------------------------------------Keep an eye on this one
            // console.log(event);
            this.clearCookiesAndSession();
            let modal = <Modal title={this.cleanHelpHTML(event.error)} help={this.cleanHelpHTML(event.help)} kill={re} bgClose={true} isMsg={true}/>
            this.setState({handle:modal});
            
        }else if(event.status===534){
            // CONNECTION ISSUES AT CANCEL
            let modal = 
            <Modal 
                title={this.cleanHelpHTML(event.error)} 
                help={this.cleanHelpHTML(event.help)} 
                extraHelp={this.cleanHelpHTML(event.extra_help)} 
                kill={this.killModal} 
                isMsg={true}
            />
            this.setState({handle:modal});

        }else if(event.status===600){
            // FRONT END ERROR - YOU'VE BEEN AWAY FOR A WHILE
            this.clearCookiesAndSession();
            this.redirect("/")
        }
    }

    cleanHelpHTML=(help)=>{
        let newHelp;
      try {
          newHelp = parse(String(help));
      } catch (error) {
          newHelp = help;
      }
      return newHelp;
    }

    clearCookiesAndSession=()=>{
        // const cookies = new Cookies();
        // cookies.remove('lt_be_params',{ path: '/' });
        // cookies.remove('lt_token',{ path: '/' });
        // cookies.remove('lt_limit',{ path: '/' });
        // cookies.remove('session_ID',{ path: '/' });
        // sessionStorage.removeItem("session");
        const monster = new CookieMonster();
        monster.eatAllCookies();
    }

    redirect=(url)=>{
        setTimeout(()=>{document.location.replace(`${url}`)}, this.state.redirectCountDown);
    }

    re=(url)=>{
        document.location.replace(`/`);
    }

    killModal=()=>{
        this.setState({handle:false});
    }

    componentWillMount(){
        if(this.props.event===false){
            this.setState({handle:false});
        }else{
            this.eventType(this.props.event);
        }
    }



  render() { 
  return ReactDOM.createPortal(
        <div>{this.state.handle}</div>
    ,document.getElementById('portal'));
  }
}

export default Handler;
import React, { Component } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, DateRangePicker  } from 'react-date-range';
import { is_mobile,year } from '../../utilities/utilities';
import { GA_Search } from '../../utilities/utilities';
import { addDays, format, isWeekend } from 'date-fns';


class DatePicker extends Component {

    constructor(props){
        super(props);
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        
        this.state= {
            startDate: this.props.params.from,
            endDate: this.props.params.to,
            promo: this.props.params.promo,
            
            key: 'selection',
            visible:false,
            lock:false
        }
    }
    promo = React.createRef();


    show(){ this.setState({visible:true,lock:true});}
    hide(){ this.setState({visible:false,lock:false});}
    toggle(){ if(this.state.visible===true){this.hide()}else{this.show()} }


    dateRangeChange(item){
        // console.log(item);
        this.setState({startDate:item.selection.startDate,endDate:item.selection.endDate});
        this.props.handleDateRangeChange(item.selection.startDate,item.selection.endDate);
        if(item.selection.startDate !== item.selection.endDate){
            this.hide();
        }
    }

    formatDate(date) {
        var d = new Date(date);

        var month = '' + (d.getUTCMonth() + 1);
        var day = '' + d.getUTCDate();
        var  year = d.getUTCFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        console.log(`Format ${[year, month, day].join('-')}`);
        return [year, month, day].join('-');
    }

    userDate(date){
        if(date ==='' || date===undefined){return '-';}
        var options = {
            weekday: "short", //to display the full name of the day, you can use short to indicate an abbreviation of the day
            day: "numeric",
            month: "short", //to display the full name of the month
            year: "numeric",
            timeZone: "UTC"
        }
        try {
            var sDay = date.toLocaleDateString("en-US", options);
            console.log("sDay " + sDay);
            return sDay;
            
        } catch (error) {
            return '-';
        }
    }

    search(){
        let promo = this.promo.current.value === "" ? false : this.promo.current.value;
        if(true){
            this.props.performSearch(this.state.startDate,this.state.endDate,true,true,promo);
            GA_Search(this.props.params.from,this.props.params.to,this.props.params.adult,this.props.params.child);
        }
    }

    noAvailability(){
        try {
            
            let yearAvail = year();
            let output = [];
            Object.keys(yearAvail).forEach(d=>{  
                if(!yearAvail[d]){
                    output.push( new Date(d) );
                }
            });
            // console.log(output);
            return output;
        } catch (error) {
            
        }
    }

    customDayContent(day) {
        try {
            
            let extraDot = null;
            let yearAvail = year();
            // console.log(yearAvail);
            if (format(day, "Y-MM-dd") in yearAvail && !!yearAvail[ format(day, "Y-MM-dd") ]){
                // console.log("hi");
                // console.log(format(day, "Y-MM-dd"));
                extraDot = (
                    <div className='min-rate'>$
                        {yearAvail[ format(day, "Y-MM-dd") ]}
                    </div>
                    )
            }else{
                // console.log(format(day, "Y-MM-dd"));
                // console.log("no");
            }
            // if (isWeekend(day)) {
                // }
                return (
                    <>
                {extraDot}
                <div>
                    <span>{format(day, "d")}</span>
                </div>
                </>
            )
        } catch (error) {
            
        }
    }

    render(){

      return(
        <>
        <div id="booking-widget" className='search-params flex flex-walls no-gap flex-y-center'>
            <div className='flex flex-even'>
                <div className='spec date-picker' onClick={()=>{this.toggle()}}>
                    <div className='flex flex-walls'>
                        <div className='date-spec'>
                            <label>Check-in</label>
                            <span>{this.userDate(this.props.params.from)}</span>
                        </div>
                        <img src="/images/calendar.svg" alt='calendar icon' width="28px" className='icon'/>
                    </div>
                </div>

                <div className='spec date-picker'  onClick={()=>{this.toggle()}}>
                    <div className='flex flex-walls'>
                        <div className='date-spec'>
                            <label>Check-out</label>
                            <span>{this.userDate(this.props.params.to)}</span>
                        </div>
                        <img src="/images/calendar.svg" alt='calendar icon' width="28px" className='icon'/>
                    </div>
                </div>
                
                <div className="spec head-spec">
                    <label>Adults</label>
                    <div className='flex flex-y-center flex-walls'>
                        <span className='value'>{this.props.params.adult}</span>
                        <div>
                            <span className="qty" onClick={()=>{this.props.handleDateGuestChange(-1,'adult')} } >−</span>
                            <span className="qty" onClick={()=>{this.props.handleDateGuestChange(1,'adult')} } >+</span>
                        </div>

                    </div>
                </div>

                <div className="spec head-spec">
                    <label>Children</label>
                    <div className='flex flex-y-center flex-walls'>
                        <span className='value'>{this.props.params.child}</span>
                        <div>
                            <span className="qty" onClick={()=>{this.props.handleDateGuestChange(-1,'child')} } >−</span>
                            <span className="qty" onClick={()=>{this.props.handleDateGuestChange(1,'child')} } >+</span>
                        </div>
                    </div>

                </div>
                <input ref={this.promo} type="text" name="promo" placeholder='Promo Code' defaultValue={this.state.promo} />

            </div>
            <div className=''>
                <button type="button" className='btn btn-main' onClick={()=>{this.search()}}>Find my room</button>
            </div>
                
                
        </div>
        <div className='holder'>
        <div id="escape" onClick={()=>{this.toggle()}} className={`${this.state.visible === true ? "active":""}`}></div>
        
          <DateRange
            className={`calendar ${this.state.visible === true ? "active":""}`}
            onChange={(item)=>{this.dateRangeChange(item)}}
            disabledDates={this.noAvailability()}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={is_mobile()===false?4:1}
            ranges={[{
                startDate: this.props.params.from,
                endDate: this.props.params.to,
                key:this.state.key,
                visible:this.state.visible,
                lock:this.state.lock
            }]}
            direction="horizontal"
            dayContentRenderer={this.customDayContent}
            showMonthAndYearPickers={true}
            preventSnapRefocus={true}
            editableDateInputs={false}
            showMonthArrow={true}
            minDate={new Date()}
            />
        </div>
        </>
      );
    }    
    
  }


export default DatePicker
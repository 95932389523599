import React, { Component } from 'react'
import countries from '../../data/countries'
import US from '../../data/US'
import CA from '../../data/CA'
import GB from '../../data/GB'
import Cookies from 'universal-cookie';
import Handler from '../Handler/Handler';
import parse from 'html-react-parser';


class CheckoutExtras extends Component {

    state={
        countries:{US,CA,GB},
        months: ["01","02","03","04","05","06","07","08","09","10","11","12"],
        states:false,
        ccType:'',
        invalid:[],
        handle:false,
        reservation:this.props.reservation,
        modifyingCard:false,
        ccExpiry:{month:false,year:false},
        msg:""
    }

    msg = React.createRef();
    ccType = React.createRef();
    ccExpiry = React.createRef();
    expiry_date = React.createRef();
    expiry_month = React.createRef();
    expiry_year = React.createRef();
    cvv = React.createRef();
    card_name = React.createRef();

    modifyPaymentMethod=(e)=>{
        this.ccType.current.disabled=!this.ccType.current.disabled;
        this.expiry_month.current.disabled=!this.expiry_month.current.disabled;
        this.expiry_year.current.disabled=!this.expiry_year.current.disabled;
        this.ccExpiry.current.disabled=!this.ccExpiry.current.disabled;
        this.cvv.current.disabled=!this.cvv.current.disabled;
        this.card_name.current.disabled=!this.card_name.current.disabled;
        let button = e.target;
        // console.log(button);
        button.innerText=this.ccType.current.disabled === true ? "Change Payment Method": "Keep previous Payment Method";
        this.setState(prevState=>{return( {modifyingCard:!prevState.modifyingCard} )})
    }

    failedOrder=(response)=>{
        let newHelp;
        let newExtraHelp;
            try {
                newHelp = parse(String(response.help));
                newExtraHelp = parse(String(response.extra_help));
            } catch (error) {
                newHelp = response.help;
                newExtraHelp = response.extra_help;
            }
        let msg = 
        <>
            <h4>Oops! Something is wrong</h4>
            {newHelp}<br/>
            {newExtraHelp}
        </>
        this.setState({msg:msg});
        this.msg.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }


    checkForm=(e)=>{
        e.preventDefault();
        let button = e.nativeEvent.submitter;
        let checks = [];
  
        let invalid = checks.map(f=>{
            return {ref:f.field,response:f.validation(f.field.value)};
        });

        let invalidCount = invalid.some(v=>{
            if(!v.response.pass){
                var ref = v.ref;
                if(v.response.hasOwnProperty("focus")){ref=v.response.focus}
                ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
                ref.classList.add("invalid");
                return true;
            }
        });

        if(!invalidCount){
            return true;
        }else{
            button.disabled=false;
            button.innerText="Complete Order";
            return false;
        }
    }

    checkout=(e)=>{
        e.preventDefault();
        let button = e.nativeEvent.submitter;
        button.disabled=true;
        button.innerText="Processing Order...";
        let type="create";

        if(!this.checkForm(e)){return;}
        const cookie = new Cookies();
        let session_ID = cookie.get('session_ID')!==undefined ?cookie.get('session_ID') : "";
        let data = new FormData(e.target);
        let body = {        
            first_name: data.get('first_name'),
            last_name: data.get('last_name'),
            phone: data.get('phone'),
            email: data.get('email'),
            country: data.get('country'),
            state: data.get('state'),
            address_1: data.get('address_1'),
            address_2: data.get('address_2') !== "" ? data.get('address_2') :"",
            city: data.get('city'),
            zip: data.get('zip'),
            additional_details: data.get('additional_details') !== "" ? data.get('additional_details') :"",
            card_num: data.get('card_num'),
            expiry_date:`${data.get('expiry_month')} ${data.get('expiry_year')}`,
            cvv: data.get('cvv'),
            card_name: data.get('card_name'),
            i_prefer: data.get('i_prefer'),
            newsletter: data.get('newsletter'),
            policy: data.get('policy'),
            booking_conditions: data.get('booking_conditions'),
            ccType:this.state.ccType,
            id:this.props.id,
            res_id:this.props.res_id,
            token:this.props.token
        }

        const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
        };

        
        
        let path = process.env.REACT_APP_EXTRA_PAYMENT ;
        console.log(`${process.env.REACT_APP_BACK}${path}`);
        console.log(body);

        fetch(`${process.env.REACT_APP_BACK}${path}`, options)
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if(response.type==='success-order'){
                // console.log(response);
                this.props.handleSuccessfulOrder(response,type);
                
            // 432 is a soft success so won't be treated as hard fail
            }else if(response.type==='error' && response.status !== 432){
                button.disabled=false;
                button.innerText="Complete Order";

                this.failedOrder(response);
            }
            return response;
        })
        .then(response=>{
            this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={response} />});
        })
        .catch(err => console.error(err));
    }

    

    handleCountrySelect=(e)=>{
        if(!this.state.countries.hasOwnProperty(e.target.value)){
            this.setState({states:false});
            return;
        }
        this.setState({states:this.state.countries[e.target.value]});
    }

    handleTypeOfCC=(e)=>{
        let type = this.CCtype(String(e.target.value).replaceAll(" ",""));
        if(type!==undefined){
            this.setState({ccType:type});
        }
    }

    expiryDate=(e,type)=>{
        this.setState(prevState=>{
            let newExp = prevState.ccExpiry
            newExp[type]=e.target.value;
            return({ccExpiry:newExp})
        });
    }

    ccExpired=()=>{
        let values = this.state.ccExpiry;
        let currentYear = Number(String(new Date().getFullYear()).slice(-2)) + 2000;
        let cardYear = Number(String(values.year).slice(-2)) + 2000;
        if(cardYear > (currentYear+10)){
            cardYear = Number(String(values.year).slice(-2)) + 1900;
        }
        let cardMonth = Number(String(values.month).substring(0, 2));
        if(cardMonth>12){return {pass:false,msg:"Invalid Month"};}
        let currentMonth = Number(String(new Date().getMonth())) + 1;
        if(parseInt(currentYear) > parseInt(cardYear)){
            return {pass:false,msg:"Card has expired",focus:this.expiry_date.current};
        }
        if(parseInt(currentMonth) > parseInt(cardMonth) && parseInt(currentYear) === parseInt(cardYear)){
            return {pass:false,msg:"Card has expired",focus:this.expiry_date.current};
        }
        return {pass:true};
    }

    ccValidType=(value)=>{
        if(String(value).length < 15){return {pass:false,msg:"Characters missing"};}
        let type = this.state.ccType;
        if(type==="" || type===undefined){
            return {pass:false,msg:"Invalid Card"};
        }
        return {pass:true}
    }

    CCtype=(cc)=>{
        // console.log(cc);
        let amex = new RegExp('^3[47][0-9]{13}$');
        let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
        let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

        let mastercard = new RegExp('^5[1-5][0-9]{14}$');
        let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

        let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
        let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
        let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
        
        let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
        let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');

        if (cc==="") {
            return "";
        }
        if (visa.test(cc)) {
            return 'VISA';
        }
        if (amex.test(cc)) {
            return 'AMEX';
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
            return 'MASTERCARD';
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
            return 'DISCOVER';
        }
        if (diners.test(cc)) {
            return 'DINERS';
        }
        if (jcb.test(cc)) {
            return 'JCB';
        }
        if (cup1.test(cc) || cup2.test(cc)) {
            return 'CHINA_UNION_PAY';
        }
        return undefined;

    }

    formatSpaces=(e,spaces,separator="")=>{
        if(String(e.target.value)===""){return;}
        let value = String(e.target.value).replaceAll(" ",separator);
        var output='';
        if(spaces===4){output=value.match(/.{1,4}/g);}
        if(spaces===2){output=value.match(/.{1,2}/g);}
        e.currentTarget.value = output.join(' ');
    }

    cleanInvalid=(e)=>{
        if(e.target.className!==''){
            e.target.className='';
        }
    }

    cleanExpired=()=>{
        if(this.expiry_date.current.classList.contains("invalid")){
            this.expiry_date.current.classList.remove("invalid");
        }
    }

  render() {
      let resDetails = this.props.data === undefined ? false : this.props.data;
      let currentYear = Number(String(new Date().getFullYear()).slice(-2));
    //   console.log(modify);
      return (
        <>
        {this.state.handle}
        <div className='box'>
        <h3 className='br-10'>Checkout</h3>
        <form onSubmit={(e)=>{this.checkout(e)}} >
            <section>
                <h4 className=''>Contact Info</h4>    
                <div className='flex flex-even'>
                    <input type="text" name="first_name" placeholder='First Name' required={true} />
                    <input type="text" name="last_name" placeholder='Last Name' required={true} />
                </div>
                <div className='flex flex-even'>
                    <input type="tel" name="phone" placeholder='Phone Number' required={true} />
                    <input type="email" name="email" placeholder='Email' required={true} />
                </div>
            </section>

            <section>
                <h4 className=''>Billing Address</h4>    
                    <div className=''>
                        <select name="country" onChange={(e)=>{this.handleCountrySelect(e);}} required={true}>
                            <option key="798765423" value=""  disabled selected> Select a Country </option>
                            <option key="123456498" value="US" > United States </option>
                            <option key="132165498" value="CA" > Canada </option>
                            <option key="1232135654" value="GB" > United Kingdom </option>
                            <option key="1231654987" disabled>----------------------------</option>
                            {countries.map((c,j)=>{return <option key={j} value={c.code}>{c.name}</option> })}
                        </select>

                        {/* STATES */}
                        {this.state.states !== false ? 
                            <select defaultValue="0" name="state" required={true}>
                                <option key="46513265" value="" disabled>State / Province</option>
                                {Object.entries(this.state.states).map(([key, value],j)=>{ 
                                    return <option key={j} value={key}>{value}</option> 
                                })}
                            </select>
                        :""}
                    </div>
                    <div className='flex flex-even'>
                        <input type="text" name="address_1" placeholder='Address 1' required={true} />
                        <input type="text" name="address_2" placeholder='Address 2' />
                    </div>
                    <div className='flex flex-even'>
                        <input type="text" name="city" placeholder='City' required={true} />
                        <input type="text" name="zip" placeholder='Zip / Postal Code' required={true} />
                    </div>
                </section>

                                
                <section>
                <h4 className=''>Additional Details and Preferences</h4>
                    <textarea  name="additional_details" placeholder="Please note your requests or special needs"></textarea>
                
                </section>

                <section>
                    <h4 className=''>Payment Information</h4>
                    <p className='br-10'>{this.props.paymentPolicy}</p>
                    <div className=''>
                        <div className='flex pad-y-20px card-icons'>
                            <img alt="card" src='/images/Visa.png' className={`${this.state.ccType==='VISA'?"activeCard":""} card`}  />
                            <img alt="card" src='/images/MasterCard.png'  className={`${this.state.ccType==='MASTERCARD'?"activeCard":""} card`} />
                            <img alt="card" src='/images/Amex.png'  className={`${this.state.ccType==='AMEX'?"activeCard":""} card`} />
                            <img alt="card" src='/images/Discover.png'  className={`${this.state.ccType==='DISCOVER'?"activeCard":""} card`} />
                        </div>

                        <div className='flex flex-even'>
                            <input  ref={this.ccType} type="text" name="card_num" placeholder='Card Number' maxLength="19" required={true} onChange={(e)=>{ this.ccInput = this.handleTypeOfCC(e);this.formatSpaces(e,4);this.cleanInvalid(e) }}/>
                            <input ref={this.card_name} type="text"   name="card_name" placeholder='Name on Card' required={true}/>
                        </div>
                        <div className='flex flex-even' id="expiry" ref={this.expiry_date}>
                            <select ref={this.expiry_month} name="expiry_month"  onChange={(e)=>{this.expiryDate(e,"month");this.cleanExpired()}}>
                                <option key="32165" vale={null}>Expiry Month</option>
                                {this.state.months.map((month,i)=>{return <option key={i} value={month}>{month}</option>})}
                            </select>

                            <select ref={this.expiry_year} name="expiry_year" onChange={(e)=>{this.expiryDate(e,"year");this.cleanExpired()}}>
                                <option key={0} vale={null}>Expiry Year</option>
                                <option key={1} value={currentYear}>{currentYear}</option>
                                <option key={2} value={currentYear+1}>{currentYear+1}</option>
                                <option key={3} value={currentYear+2}>{currentYear+2}</option>
                                <option key={4} value={currentYear+3}>{currentYear+3}</option>
                                <option key={5} value={currentYear+4}>{currentYear+4}</option>
                                <option key={6} value={currentYear+5}>{currentYear+5}</option>
                                <option key={7} value={currentYear+6}>{currentYear+6}</option>
                            </select>
                            <input type="hidden" name="expiryDate" ref={this.ccExpiry} />

                            <input ref={this.cvv} type="text" name="cvv"  placeholder='CVV' maxLength="4" required={true} />
                        </div>
                        
                    </div>
                </section>

                
                <p ref={this.msg} className='user-msg'>{this.state.msg}</p>
                
                <br/>
                
                <div className='flex flex-y-center'>
                    <button type='submit' className="btn-book">Complete Order</button><br/>
                    <img alt="cybersource" src='/images/cs-logo.png' width="90"/>
                </div>


        </form>
    </div>
    </>
    )
  }
}

export default CheckoutExtras
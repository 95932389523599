import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Modal from '../Modal/Modal';


class LogIn extends Component{

    kill=()=>{
       this.props.handleKillLogIn();
    }

    isign=(e)=>{
        e.preventDefault();
    }

    loginForm=()=>{
        let form = 
        <>
        <form className='br-10'>
            <input type="text" placeholder='Username'/>
            <input type="password" placeholder='password'/>
            <div className='flex flex-even br-10'>
                <div className='flex flex-y-center'>
                    <input type="checkbox" /><p>Book as a travel arranger</p>
                </div>
                <button onClick={(e)=>{this.isign(e)}}>Sign in</button>
            </div>
        </form>
        <a target="_blank" href="https://preferredhotels.com/iprefer/members/forgot-password"><span>Forgot Password?</span></a>
        <hr/>
        <p>Not an I Prefer member?</p>
        <a href="https://preferredhotels.com/iprefer/enroll?ip=1"><span>Join now for savings and instant member benefits.</span></a>
        </>
        return form;
    }

    render(){
        return ReactDOM.createPortal(
        <Modal title="Log in as I Prefer" help={this.loginForm()}  kill={this.kill}/>
        ,
        document.getElementById('portal')
        )
    }
}

export default LogIn;

import React, {useEffect,useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import SummaryExtras from '../../components/Summary/SummaryExtras';
import CheckoutExtras from '../../components/Checkout/CheckoutExtras';
import { re } from '../../utilities/utilities';
import parse from 'html-react-parser';



const CheckoutTerminal = ({handleSuccessfulOrder}) => {

      const [charges,setCharges]=useState([]);
      const [err,setErr]=useState([]);

      useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        fetch(`${process.env.REACT_APP_BACK}/api/rescharges-checkout?res=${urlParams.get('res')}&t=${urlParams.get('t')}&id=${urlParams.get('id')}` )
          .then(response => response.json())
          .then(response => {
            // console.log(response);
            if(response===null ){re("/");return;}
            if(response.type==="error"){
              let e = <> <h1>{parse(String(response.error))}</h1> <p>{parse(String(response.help))}</p> <b><u>You are about to be redirected.</u></b></> 
              setErr(e);
              setTimeout(() => { re(); }, 7000);
              return;
            }

            console.log(JSON.parse(response.guest_order));
            let e = false;
            setErr(e);
            setCharges({
              ...response,
              'guest_order':JSON.parse(response.guest_order),
              'id':urlParams.get('id'),
              'res_id':urlParams.get('res'),
              'token':urlParams.get('t')
            });

          })
          .catch(err => console.error(err));

      },[]);

 
    return (
      <>
      {window.scrollTo(0, 0)}
      <div id="room-page" className='container br-5vh'>
        <div id="head">
          <Navbar />
        </div>

        {err!==false?
          <div className='user-msg'>{err}</div>
        : 
        <div className='flex col-body layout-60-40'>
          <div className='flex-col'>
                <CheckoutExtras
                  handleSuccessfulOrder={handleSuccessfulOrder}
                  data={charges.guest_order === undefined ? "" : charges.guest_order.details}
                  id={charges.id}
                  res_id={charges.res_id}
                  token={charges.token}
                />
          </div>

          <div className=''>
              <div className='holder'>
                <SummaryExtras 
                  data={charges.guest_order === undefined ? "" : charges.guest_order.charges}
                  total={charges.amount}
                />
              </div>
        </div>

              
        </div>
        }
      </div>

    </>
  )

}

export default CheckoutTerminal;
import React, {Component} from 'react'
import MiniSlideshow from '../MiniSlideshow/MiniSlideshow';
import Rate from '../Rate/Rate';
import { AppContextConsumer } from '../AppContext/AppContext'
import LogIn from '../LogIn/LogIn';
import irates from '../../data/iprefer';
import RateDetailsPop from '../Rate/RateDetailsPop';



class SingleRoom extends Component{

  state={
    open:false,
    amenities:[],
    rateDetails:false,
    logIn:false,
    details:false,
  }

  handleLogIn=()=>{
    this.setState(prevState=>{
      return {logIn:<LogIn handleKillLogIn={this.handleKillLogIn} />}
    });
  }

  handleKillLogIn=()=>{
    this.setState(prevState=>{
      return {logIn:false}
    });
  }

  handleDetails=(data,context,roomCode,ratePlan)=>{
    this.setState(prevState=>{
      return {details:<RateDetailsPop 
                        data={data}
                        roomCode={roomCode}
                        ratePlan={ratePlan}
                        context={context}
                        handleKillDetails={this.handleKillDetails} 
                      />}
    });
  }
  
  handleKillDetails=()=>{
    this.setState(prevState=>{
      return {details:false}
    });
  }

  openRoom=()=>{
    this.setState(prevState=>{
      return {open:!prevState.open}
    });
  }

  sortRates(sortOrder=1) {
    let key = "props.data.allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax";
        return (a,b)=>{
            var result = ( eval(`${a.key}`) < eval(`${b.key}`) ) ? -1 : ( eval(`${a.key}`) > eval(`${b.key}`) ) ? 1 : 0;
            return result * -1;
        }
    }

  getRates=(context)=>{
    let objRates = this.props.data.rates;
    var lowest = 0.0;
    var lowestRate;
    let rates = Object.keys(objRates).map((rate,i)=>{
      if(!irates.includes(rate)){
              let allRatePlans = context.RatePlans;
              let ratePlan = allRatePlans.constructor === Array ? allRatePlans.find(r =>{ return r['@attributes']['RatePlanCode'] === rate; }) : allRatePlans ;

              const output = 
                <Rate 
                  key={i} 
                  code={rate} 
                  data={objRates[rate]} 
                  handleRoomRateSelection={this.props.handleRoomRateSelection} 
                  roomCode={this.props.data["@attributes"].RoomTypeCode}
                  ratePlan={ratePlan}
                  handleRateDetails={this.props.handleRateDetails}
                  rateDetail={this.props.rateDetail}
                  handleLogIn={this.handleLogIn}
                  handleDetails={this.handleDetails}
                  context={context}
                />
              // console.log(output);
              if(lowestRate===undefined){
                lowest= objRates[rate].allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax;
                lowestRate = output;
              }
              if(parseFloat(objRates[rate].allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax) < parseFloat(lowest)){
                lowest = objRates[rate].allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax;
                lowestRate = output;
              }else{
                lowest = lowest;
                lowestRate = lowestRate;
              }
              return output;          
            }
        });

    rates = rates.filter(r=>{return r!==lowestRate});
    rates = rates.sort(this.sortRates());
    // console.log(lowestRate);
    // console.log(rates);
    // CHECK IN CASE THERE IS ONLY ONE RATE IN TOTAL! 
    if(lowestRate===undefined && rates.length === 1){
      lowestRate = rates[0];
      rates = [];
    }
    
    return (
        <>
          {lowestRate}
          {rates}
        </>
    );
  }



  render(){
    return (
      <AppContextConsumer>
        {context=>{
            // CHECK FOR CONTEXT

            if(Object.keys(context).length === 0){
              // console.log("A");
              if(sessionStorage.hasOwnProperty('session')){
                // console.log("B");
                context = JSON.parse(sessionStorage.getItem('session'));
              }else{
                alert('lets redirect')
              }
            }

          // CHECK FOR CONTEXT

          let rates = this.props.hasOwnProperty('single') === true && this.props.single === true ? "" : this.getRates(context) ;            
          // let description = this.props.hasOwnProperty('single') === true && this.props.single === true ? <p>{this.props.data.webDescription.description}</p> : "" ;            
          let bullets = "";
          if(this.props.data.webDescription.hasOwnProperty("extra_info")){
            bullets = this.props.data.webDescription.extra_info.map((bullet,i)=>{if(i<4){return <li key={i}>{bullet}</li>}});  
          }
          
          let noRooms = this.props.data.TPA_Extensions.BedTypes.BedType.length;
          if(noRooms===undefined){ noRooms=1 }
          let amenities=[];
          
          if(this.props.data.webDescription.hasOwnProperty("amenities")){
            
          amenities = this.props.data.webDescription.amenities.map((a,i)=>{
            let allAmenities = context.amenities;
              try {
                return <div key={i} className='flex room-ammenitie'>
                  <div id="single-amenity">
                    <img src={allAmenities[a].icon} alt={`${allAmenities[a].name} icon`} />
                    <div className='amenity-description'>{allAmenities[a].name}</div>
                  </div>
                </div>
              } catch (error) {
                console.error(error);
              }
            }
          );
          }
          
            return(
              
              <div id="room" className={`
                room-anim
                ${this.state.open === true ? "open" : ''}`
              } data-room={this.props.data["@attributes"].RoomTypeCode} data-lowest={this.props.data.lowest} key={this.props.data["@attributes"].RoomTypeCode} data-no={noRooms}>

                {this.state.details}
                <MiniSlideshow key={this.props.data["@attributes"].RoomTypeCode} RoomTypeCode={this.props.data["@attributes"].RoomTypeCode} noRooms={noRooms} />
                <div className='info'>
                  {/* We're overriding the unit name */}
                  <h3>
                    {this.props.data.webDescription.unit_name?
                    this.props.data.webDescription.unit_name
                  :this.props.data.RoomDescription["@attributes"].Name}
                  {/* _{this.props.data["@attributes"].RoomTypeCode} */}
                  </h3>
                  {/* <h3>{this.props.data.RoomDescription["@attributes"].Name}</h3> */}
                  <h4 className='subtitle'>{this.props.data.webDescription.subtitle}</h4>
                  <div className='flex feats'>
                    <p>Sleeps: {this.props.data.Occupancy["@attributes"].MaxOccupancy}</p>
                    <p>Bedrooms: {noRooms}</p>

                    {this.props.data.webDescription.hasOwnProperty("noBathrooms") ?
                      <p>Bathrooms: {this.props.data.webDescription.noBathrooms}</p>
                    :""}

                    {this.props.data.webDescription.hasOwnProperty("sqft") ?
                      <p>{this.props.data.webDescription.sqft} sqft</p>
                    :""}

                  </div>
                  <div className='ammenities'>{amenities}</div>
                  {/* <ul id="bullets">{bullets}</ul> */}
                  {rates}
                  {/* {description} */}
                </div>
              </div>
            );
          }}
        </AppContextConsumer>


    )
  }
}




export default SingleRoom
import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Modal from '../Modal/Modal';
import Summary from '../Summary/Summary';
import RateDescription from './RateDescription';


class RateDetailsPop extends Component{

    state={
       popUpActive:false 
    }

    kill=()=>{
       this.props.handleKillRateDetailsPop();
    }

    isign=(e)=>{
        e.preventDefault();
    }

    RateDetailsPopForm=()=>{
        const room = this.props.context.rooms.find(r=>{return r["@attributes"].RoomTypeCode === this.props.roomCode})
        // console.log(this.props);
        // console.log(room);
        let content =
        <>
            <div id="" className={`${this.state.popUpActive===true?'active':''}`}>
                <div className='flex flex-even'>
                    <div>
                        <h3 className='br-20'>{room.RoomDescription["@attributes"].Name}</h3>
                        <RateDescription ratePlan={this.props.ratePlan} />
                    </div>
                    <Summary 
                        data={this.props.data}
                        roomCode={this.props.roomCode}
                        ratePlan={this.props.ratePlan}
                    />
                </div>
            </div>
        </> 

        return content;
    }

    render(){
        return ReactDOM.createPortal(
        <Modal title="" help={this.RateDetailsPopForm()}  kill={this.props.handleKillDetails} bgClose={true} />
        ,
        document.getElementById('portal')
        )
    }
}

export default RateDetailsPop;

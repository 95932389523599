import React from 'react'


const Modal = ({title,help,kill,extraHelp,bgClose=false,isMsg=false}) => {
  return (
    <div id="full-modal">
        <div id="modal-container" onClick={bgClose===true?()=>{kill()}:""}>
            <div className={`${isMsg===true?"msg-modal":""} modal`} onClick={(e)=>{e.stopPropagation();}}>
            <div onClick={()=>{kill()}} id="close">✖</div>
                <h3 className='br-20'>{title}</h3>
                <p>
                    {help}
                </p>
                {extraHelp!=="" ? extraHelp: <><br/><p>{extraHelp}</p></>}
            </div>
        </div>
    </div>
  )
}

export default Modal;

export const lt_errors={
    401:{
            "type": "error",
            "status": 401,
            "error": "You've been away for a while",
            "help": "Let's update your search",
        },
    600:{
            "type": "error",
            "status": 600,
            "error": "You've been away for a while",
            "help": "Let's update your search",
    }
}
import React, {useContext} from 'react';
import { AppContext } from "../../components/AppContext/AppContext";
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import SingleRoom from '../../components/SingleRoom/SingleRoom';
import Summary from '../../components/Summary/Summary';
import Checkout from '../../components/Checkout/Checkout';
import { lt_errors } from '../../utilities/errors';
import { CookieMonster, re } from '../../utilities/utilities';
import irates from '../../data/iprefer';
import { ModifySummary } from '../../components/ModifySummary/ModifySummary';


const Room = ({handle,handleSuccessfulBooking,imember,exitEditMode}) => {

      let { roomCode,rateCode } = useParams();
      let context = useContext(AppContext);
      let header = "";
      let redirect = false;

     

      imember = imember === undefined ? false : imember;
      if(irates.includes(rateCode)===true && imember===false){
        handle(lt_errors[600]);
        // return;
      }

      if(Object.keys(context).length === 0){
        if(sessionStorage.hasOwnProperty('session')){
          context = JSON.parse(sessionStorage.getItem('session'));
        }else{
          redirect = true;
          // alert('lets redirect');
        }
      }

     // MODIFY MODE
      if(context.hasOwnProperty("modify")){
        // console.log(context);
        header = <ModifySummary reservation={context.modify} exitEditMode={exitEditMode} />
      } 

  
    return (
      <>
      {context.selection===undefined ? re() : "" }
      {window.scrollTo(0, 0)}
    <div id="room-page" className='container br-5vh'>
      <div id="head">
        <Navbar />
      </div>
      <div className='flex col-body layout-60-40'>
        <div className='flex-col'>
              <SingleRoom 
                data={context.rooms.find(r =>{ return r['@attributes']['RoomTypeCode'] === roomCode; })}
                handleRoomRateSelection={false}
                single={true}
                />
                <Checkout
                  paymentPolicy={context.selection.data.allInfo.Rates.Rate.PaymentPolicies.GuaranteePayment.Description.Text}
                  cancelPolicy={context.selection.data.allInfo.Rates.Rate.CancelPolicies.CancelPenalty.PenaltyDescription.Text}
                  handleSuccessfulBooking={handleSuccessfulBooking}     
                  reservation={context.hasOwnProperty("modify")?context.modify:false}             
                />
        </div>

        <div className=''>
            <div className='holder'>
              <Summary 
                data={context.selection.data}
                roomCode={context.selection.roomCode}
                ratePlan={context.selection.ratePlan}
                bookNow={false}
                header={header}
                />
            </div>
      </div>

            
      </div>
    </div>

    </>
  )

}

export default Room
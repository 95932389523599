import React,{Component} from 'react'
import SingleRoom from '../SingleRoom/SingleRoom';
import { is_mobile, mobile_collapse } from '../../utilities/utilities';

class RoomsContainer extends Component{
  
    state={
        sort:"noRooms",
        order:1,
        data:[],
    }

    sortData=(data,key=this.state.sort)=>{
        let output = data.sort(this.sortBy(key));
        if(this.state.order===-1){output.reverse();}
        // this.setState({data:output})
        return output;
    }

    sortBy(key,sortOrder=1) {
        return (a,b)=>{
            var result = ( eval(`${a.key}`) < eval(`${b.key}`) ) ? -1 : ( eval(`${a.key}`) > eval(`${b.key}`) ) ? 1 : 0;
            return result * sortOrder;
        }
    }
    

    changeSort=(key,order)=>{
        this.props.sortRooms(key,order)
        this.setState(prevState=>{
            // let order = prevState.sort === key ? this.state.order * -1 : 1 ; 
              return {
                  sort: key,
                  order: order,
              }
          }
      ); 
    }

  render(){
    // console.log(this.props.rooms);
      // let sortedRooms = this.sortData(this.props.rooms,'TPA_Extensions.BedTypes.BedType.length');
      let sortedRooms = this.props.rooms;
      if(sortedRooms.length>0){
        var allRooms = sortedRooms.map((room,i)=>{
          try{

            return <SingleRoom 
            key={i}
            delay={i}
            data={room} 
            handleRoomRateSelection={this.props.handleRoomRateSelection} 
            handleRateDetails={this.props.handleRateDetails}
            rateDetail={this.props.rateDetail}
            />
          }catch(err){
            console.log(err);
          }
        });

        var filters =
        <>
          <button className={
            `sec ${this.state.sort==='noRooms' && this.state.order ===1 ? "selected" : ""}`
            } onClick={(e)=>{this.changeSort('noRooms',1)}}>No. Rooms (Lowest)</button>
          <button className={
            `sec ${this.state.sort==='noRooms' && this.state.order ===-1 ? "selected" : ""}`
            } onClick={(e)=>{this.changeSort('noRooms',-1)}}>No. Rooms (Highest)</button>
          <button className={
            `sec ${this.state.sort==='lowest' && this.state.order ===1 ? "selected" : ""}`
            } onClick={(e)=>{this.changeSort('lowest',1)}}>Price (Lowest)</button>
          <button className={
            `sec ${this.state.sort==='lowest' && this.state.order ===-1 ? "selected" : ""}`
            } onClick={(e)=>{this.changeSort('lowest',-1)}}>Price (Highest)</button>
        </>
      }else{
        // ADD LOADER
        var allRooms = <div>{this.props.searchMessage}</div>;
        var filters = '';
      }
      return (
        <>
        <div id="filters" className='flex flex-y-center pad-y-20px flex-walls'>
          {filters!==""?
            <>
              <div>We found <b>{allRooms.length} results</b> based on your needs</div>
            </>
          :""}
          
          {is_mobile()?<div className='collapse-trigger' onClick={(e)=>{mobile_collapse(e,'show-filters')}}><div className='flex flex-walls'><div>Show Filters</div><div className='icon'>▼</div></div></div>:""}

          <div id='show-filters' className='collapsable'>
            <div className='br-10'>Filter by:</div>
            <div id="filters-container" className={`${this.state.sort} flex filters flex-y-center`}>
              {filters}
            </div>        
          </div>
        </div>


          <div id="rooms-container">
            {allRooms}
          </div>
          <br/>
        </>
    )
  }
}

export default RoomsContainer
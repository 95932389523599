import React, { useContext } from 'react'
import { formatDollar } from '../../utilities/utilities';



const SummaryExtras = ({data,total}) => {
    var charges = data !== undefined ? data : [];
    console.log(data);
    return (
        <>
        <div id="summary" className="summary-extras">
            <h4>Your Order</h4>
            <hr/>
            <div className="flex  flex-walls">
                <h5>Items</h5>
                <h5>Total</h5>
            </div>
            <br/>
            <div id="sum">
                {Object.keys(charges).map((c,i)=>{ 
                    return  <div key={i} className="flex flex-even">
                                <p>{charges[c].charge}</p>
                                {charges[c].hasOwnProperty('qty') === true ? 
                                    <p>x {charges[c].qty}</p>
                                : ""}
                                <div className="amount">{ formatDollar(charges[c].value) }</div>
                            </div>
                })}

            </div>
            <br/>
            <hr/>
            <div id="total" className="flex flex-even br-10">
                Total:
                <div className="amount">
                    {formatDollar(total)}
                </div>
            </div>
            <div>Taxes and fees included</div>
            

        </div>
        </>

    )
}

export default SummaryExtras
import Navbar from '../../components/Navbar/Navbar';
import { re,clearCookiesAndSession, CookieMonster, is_mobile } from '../../utilities/utilities';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import OrderDetails from '../../components/OrdeDetails/OrderDetails';




const ThankyouFeedback = () => {
    // const [cancelPop, fireCancelPop] = useState("");
    // function kill() {
    //     fireCancelPop("");
    // }

    let navigate = useNavigate();
    function handleModify() {
        navigate("/");
    }
    let { success } = useParams();

    const monster = new CookieMonster();
    if(monster.get("lt_r")===undefined){
        re("/")
    }
    monster.eatAllCookies();

    let content=
    <>
        <h4 className='subtitle'>Come again soon</h4>
        <h1 className='br-10'>Thank you for your feedback!</h1>
        <p>Thank you for taking the time to tell us about your recent stay. We’d love to welcome you home again to Las Terrazas Resort. If we can assist you in planning another Belize adventure, please reach out to the Reservations Office directly and we’ll offer you <a target="blank" href="mailto:reservations@lasterrazasbelize.com?subject=Discounted rate request for return visitor"><b><u>10% off our lowest published rates when you book direct.</u></b></a></p>
        <br/>
        <p>Warmest Belizean wishes,</p>
        <h4>Carolyn Lloyd & Stephen Calland</h4>
        <small>Co-General Managers</small>
    </>;

  return (
    <>
    {clearCookiesAndSession()}
    <div id="home" className='container no-pad'>
      <div className='flex no-gap col-body'>
        <div className='container br-10vh'>
            <div id="head">
                <Navbar />
            </div>
            <div className='pad-y-2'>
                <div className='flex flex-walls flex-break'>
                    <div id="">
                        <div>{content}</div>
                    </div>
                </div>
            </div>
        </div>
        {!is_mobile()?
            <div className='hero-container'>
                <video className="hero-shot" mute="true" autoPlay={true} loop={true}>
                    <source src="/images/02.mp4" type="video/mp4" />
                </video>
            </div>
        :""}
      </div>
    </div>

    </>
  )
}

export default ThankyouFeedback;

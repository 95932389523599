import React, { Component } from 'react';
import Handler from '../Handler/Handler';
import { TheHandler } from '../../utilities/utilities';
import  { Navigate  } from 'react-router-dom'


class FindBookingForm extends Component {

  state={
    handle:false
  }

  find=(e)=>{
    e.preventDefault();
    let data = new FormData(e.target);
    let dev = process.env.REACT_APP_BACK.includes("manage") ? `&d=ASAMFP` : "";
        // fetch('http://localhost:8000/api/payment', options)
        console.log(`${process.env.REACT_APP_BACK}/api/find-booking?confirmationNumber=${data.get('confirmationNumber')}&email=${data.get('email')}${dev}`);
        fetch(`${process.env.REACT_APP_BACK}/api/find-booking?confirmationNumber=${data.get('confirmationNumber')}&email=${data.get('email')}${dev}`)
        .then(response => response.json())
        .then(response => {
            // console.log(response);
            this.props.handleSuccessfulBooking(response);
            return response;
            // this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={output} />});
        })
        .then(response=>{
          // console.log('here too');
          if(response.status===431 || response.status===532){
            this.props.handleNotFound(response.help);
          }else{
            this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={response} />});
          }

          // this.setState({handle:handle.handle()});
        })
        .catch(err => console.error(err));
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <>
      {this.state.handle}
      <div className=''>
      <form onSubmit={(e)=>{this.find(e);}}>
          <input type="text" name="confirmationNumber" placeholder='Confirmation Number' defaultValue={urlParams.get('confirmation')} required={true}/>
          <input type="email" name="email" placeholder='E-mail' defaultValue={urlParams.get('email')} required={true}/>
          <button type="submit" className='btn-action'>Find Resevation</button>
      </form>
      </div>
      </>
    )
  }
}

export default FindBookingForm
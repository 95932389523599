const routes = {
    DELUXE:{1:{qty:4}},
    LAG3:{2:{qty:4},3:{qty:5}},
    OFSEA3:{2:{qty:7},3:{qty:7}},
    PET3:{2:{qty:7},3:{qty:1}},
    PET2:{2:{qty:7}},
    PET2OTA:{2:{qty:7}},
    REV3:{1:{qty:7},2:{qty:1},3:{qty:1}},
    REV1OTA:{1:{qty:7},2:{qty:1},3:{qty:1}},
    REV1:{1:{qty:7},2:{qty:1},3:{qty:1}},
    LAG2OTA:{2:{qty:3}},
    LAG2:{2:{qty:3}},
    REV2OTA:{1:{qty:7},2:{qty:1}},
    REV2:{1:{qty:7},2:{qty:1}},
    TS3:{3:{qty:7}},
}

export default routes;
import React, {useEffect,useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import SummaryExtras from '../../components/Summary/SummaryExtras';
import CheckoutExtras from '../../components/Checkout/CheckoutExtras';
import { re,CookieMonster } from '../../utilities/utilities';
import parse from 'html-react-parser';
import Handler from '../../components/Handler/Handler';



const GatherReviews = ({permissionEndpoint}) => {

      const [form,setForm]=useState([]);
      const [type,setType]=useState([]);
      const [links,setLinks]=useState([]);
      const [err,setErr]=useState([]);

      useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        fetch(`${process.env.REACT_APP_BACK}/api/${permissionEndpoint}?res=${urlParams.get('res')}&rate=${urlParams.get('rate')}` )
          .then(response => response.json())
          .then(response => {
            if(response===null ){re("/");return;}
            // if(response.type==="error"){
            //   let e = <> <h1>{parse(String(response.error))}</h1> <p>{parse(String(response.help))}</p> <b><u>You are about to be redirected.</u></b></> 
            //   setErr(e);
            //   setTimeout(() => { re(); }, 7000);
            //   return;
            // }
            setType(response.type)

            console.log(response);
            // console.log(JSON.parse(response));
            if(parseInt(response.type)===1){
                let e = false;
                setErr(e);
                setLinks({
                    'links':response.links,
                    'info':response.info,
                });
            }else if(parseInt(response.type)===0){
                let e = false;
                setErr(e);
                setForm({
                    'res_id':urlParams.get('res'),
                    'fields':response.fields,
                    'info':response.info,
                });
            }

          })
          .catch(err => console.error(err));

      },[]);

    function submitForm(e){
        e.preventDefault();
        let button = e.nativeEvent.submitter;
        button.disabled=true;
        button.innerText="Sendnig...";
        let type="create";

        let data = new FormData(e.target);
        let body = {        
            res_id: form.res_id,
        }
        form.fields.map(f=>{
            body[f.name]=data.get(f.name)
        });
        console.log(body);



        const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
        };

        
        
        let path = process.env.REACT_APP_FEEDBACK ;
        // console.log(`${process.env.REACT_APP_BACK}${path}`);
        console.log(body);
        console.log(`${process.env.REACT_APP_BACK}${path}`);

        fetch(`${process.env.REACT_APP_BACK}${path}`, options)
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if(response.type==='success-feedback'){
                // console.log(response);
                // this.setState({handle:<Handler key={Math.floor(Math.random() * 6) + 1} event={response} />});
                re("/thank-you-feedback/");
            }
        })
        
        .catch(err => console.error(err));
    }

    function buildForm(){
        let formHTML = form.fields.map(f=>{
            if(f.type==="textarea"){
                return <>
                    {f.description!==undefined ? <h4>{f.description}</h4> : ""}
                    <textarea name={f.name} placeholder={f.placeholder}></textarea>
                    <br/><br/>
                </>
            }else if(f.type==="stars"){
                let rates = [1,2,3,4,5];
                let stars = rates.map((s)=>{return star(s,f.name);});
                return <>
                    {f.description!==undefined ? <h4>{f.description}</h4> : ""}
                    <input name={f.name} type="hidden"/>
                    <div id="rating-container">{stars}</div>
                    <br/>
                </>
            }
        });
        return formHTML;
    }

    function score(e,target){
        let scoreSelected = e.target.getAttribute("data-score");
        Array.from(e.target.parentElement.children).forEach(star => {
            if( parseInt(star.getAttribute("data-score")) <= parseInt(scoreSelected) ){
                star.setAttribute("data-selected",1);
            }else{
                star.setAttribute("data-selected",0);
            }
        });
        document.querySelector(`[name="${target}"]`).value=scoreSelected;
    }

    function star(index,target){      
        return <div className="star" data-score={index} key={index} onClick={(e)=>{score(e,target)}}>
            <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 43 43">
            <polygon class="st0" points="21.5,1.7 26.4,16.9 42.3,16.8 29.4,26.2 34.3,41.3 21.5,31.9 8.7,41.3 13.6,26.2 0.7,16.8 16.6,16.9"/>
            </svg>
        </div>
        ;
    }

    function getBody(){
        if(type===0){
            return <>
            <div>
                <h1>{form.info.title}</h1>
                <h4 className='subtitle'>{form.info.subtitle}</h4>
            </div>
                <p>{form.info.body}</p> 
                <form onSubmit={(e)=>{submitForm(e)}}>
                    {buildForm()}
                    <button type='submit' className="btn-book">Send us your feedback!</button>
                </form>
            </>
        }else if(type===1){
            let output = links.links.map(link => {
                // console.log(link);
                return <a target="blank" href={link.url}><img src={link.img} alt="media logo" width="100" height="100"/> </a>
            });
            return <>
            <div>
                <h1>{links.info.title}</h1>
                <h4 className='subtitle'>{links.info.subtitle}</h4>
            </div>
            <p>{parse(links.info.body)}</p>
            <div className=''>
                {output}
            </div>
            </>
        }
    }

    const monster = new CookieMonster(); 
    monster.setRandomCookie();

    return (
      <>
      {window.scrollTo(0, 0)}
      <div id="room-page" className='container br-5vh'>
        <div id="head">
          <Navbar />
        </div>

        {err!==false?
          <div className='user-msg'>{err}</div>
        :
        <div className='flex col-body layout-60-40'>
            <div className='flex-col'>
                {getBody()}
            </div>
            <div></div>

        </div>
        }
      </div>

    </>
  )

}

export default GatherReviews;
import React from 'react'
import logo from '../../logo.svg';
import { NavLink} from 'react-router-dom';
import { is_mobile } from '../../utilities/utilities';
import { useState } from 'react';

const Navbar = () => {
  const [menu,toggleMenu] = useState(false);
  function openMenu(){
    toggleMenu(!menu);
  }
  return (
    <>
    <nav className={`${menu===true?"open":""} flex pad-y-2 flex-walls`} data-back={`${process.env.REACT_APP_BACK.replace("https://","").replace(".com","")}`}>
        <a href='https://lasterrazasresort.com/' target="_blank" className='main-logo'>
          <img src={logo} alt="LT Logo" width="200px"/>
        </a>      
      <ul className='flex flex-y-center'>
        <li style={{"--delay":"0s"}}> <a href="/" >Home</a> </li>
        <li style={{"--delay":"0.1s"}}> <NavLink to={`/find-booking`}>Find Reservation</NavLink> </li>
        <li style={{"--delay":"0.2s"}}><a href="tel:+18004471553">+1-800-447-1553</a></li>
        {is_mobile()===true? 
          <img alt="LT symbol" src='/images/lt_symbol.png' className='symbol' />
         :""}
      </ul>
      {is_mobile()===true? 
        <div className='menu-trigger' onClick={()=>{openMenu()}}>
          <img alt="LT symbol" src='/images/menu.png' className='menu'/>
        </div>
         :""}
    </nav>
    </>

  )
}

export default Navbar
import React from 'react';
import { userDate } from '../../utilities/utilities';
import { PackReservation } from '../SynxisReservation/PackReservation';
import { useNavigate } from 'react-router-dom';


export const ModifySummary = ({reservation,exitEditMode,prevRes=""}) => {
    let navigate = useNavigate();
    let res = PackReservation(reservation).output;

  return (
    
      <div className='br-20 box'>
        <div className='flex flex-walls flex-y-center modify-buttons'>
            <div>
                <h1 className='br-10'>You are currently in Modify Mode</h1>
                <p>Anything you do now will modify your previous reservation</p>
            </div>
            <div>
                <button className='btn-action' onClick={()=>{exitEditMode();navigate('/booking/found')}}>Exit Modify Mode</button>
            </div>
        </div>
        <br/>
        <div className='box modify-summary '>
            <div className=''>

                <h4>Your previous reservation details:</h4>
                <div className='flex flex-walls'>
                        <p><b>Room:</b><br/>{res.room}</p>

                        <p><b>Arrival Date:<br/></b> {userDate(res.start)}</p>
                        <p><b>Departure Date:<br/></b> {userDate(res.end)}</p>
                            <p><b>Adults:</b><br/> {res.guests.adult} </p><p> <b>Children:</b> <br/>{res.guests.child}</p>

                        <p><b>Night Rate Before Taxes:</b><br/> {res.dailyRateWithoutTax}</p>
                        <p><b>Total Cost with Taxes:</b><br/> {res.totalCostWithTax}</p>
                        <p><b>Confirmation Number:</b> <br/>{res.confirmationNumber}</p>
                </div>

            </div>

            
        </div>
        
      </div>
  )
}

import React, {useContext,useState} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import FindBookingForm from '../../components/Forms/FindBookingForm';
import parse from 'html-react-parser';
import { is_mobile } from '../../utilities/utilities';


const FindBooking = ({handleSuccessfulBooking}) => {

  const [msg, changeMsg] = useState("");
  function handleNotFound(response){
    changeMsg(parse(response))
  }

   return (
    <>
    <div id="home" className='container no-pad'>
      <div className='flex flex-even no-gap col-body'>
        <div className='container br-10vh'>
          <div id="head">
            <Navbar />
            <hr/>
          </div>
            <div className='pad-y-2'>
                <h1 className=''>Let's find your reservation</h1>
                <p>Please tell us your booking Confirmation Number and E-mail</p>
                <p className='user-msg'>{msg}</p>
            </div>
            <FindBookingForm handleSuccessfulBooking={handleSuccessfulBooking} handleNotFound={handleNotFound}/>
            
          
        </div>
        {is_mobile()===false?
          <div className='hero-container'>
              
              <video className="hero-shot" mute="true" autoPlay={true} loop={true}>
                  <source src="/images/test.mp4" type="video/mp4" />
              </video>
          </div>
          :""}
      </div>
    </div>

    </>
  )

}

export default FindBooking
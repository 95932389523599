import React from 'react';

export const CancelBooking = ({yes,no,yesArgs}) => {

    const modalOptions = 
    <div className='modal-options'>
        <p>Are you sure you want to cancel your reservation?</p>
        <p>This action cannot be undone.</p><br/>
        <div className='flex flex-even flex-walls'>
          <button className='btn-action' onClick={()=>{yes(yesArgs);no();}}>Yes</button>
          <button className='btn-action' onClick={()=>{no()}}>No</button>
        </div>

    </div>
  return (
    <div>{modalOptions}</div>
  )
}

import { AppContext } from "../AppContext/AppContext";
import React, { useContext } from 'react'
import { NavLink} from 'react-router-dom';
import { formatDollar } from '../../utilities/utilities';
import { ModifySummary } from "../ModifySummary/ModifySummary";



const Summary = ({data,roomCode,ratePlan,bookNow=true,header=""}) => {
    let context = useContext(AppContext);
    if(Object.keys(context).length === 0){
        if(sessionStorage.hasOwnProperty('session')){
          context = JSON.parse(sessionStorage.getItem('session'));
        }
    }
    

    
    let nightlyRate = data.allInfo.Rates.Rate.Base['@attributes'].AmountBeforeTax;    
    const nights = (date_1, date_2) =>{
        date_1 = typeof(date_1) === 'string' ? new Date(date_1) : date_1 ;
        date_2 = typeof(date_2) === 'string' ? new Date(date_2) : date_2 ;
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    let userDate =(date)=>{
        if(date ==='' || date===undefined){return '-';}
        date = typeof(date) === 'string' ? new Date(date) : date ;
        var options = {
            weekday: "short", //to display the full name of the day, you can use short to indicate an abbreviation of the day
            day: "numeric",
            month: "short", //to display the full name of the month
            year: "numeric"
        }
        try {
            var sDay = date.toLocaleDateString("en-US", options);
            return sDay;
            
        } catch (error) {
            return '-';
        }
    }

    const isInclusive = data.totals["@attributes"].AmountBeforeTax === data.totals["@attributes"].AmountAfterTax ? true : false ;

  return (
      <>
    {header}
    <div id="summary">
        <h4>{header ? "Your new stay" : "Your Stay"}</h4>
        <div className='flex flex-even'>
            <div>
                <label>Check-in:</label>
                <p>{userDate(context.from)}</p>
                <p>After 3:00 PM</p>
            </div>

            <div>
                <label>Check-out:</label>
                <p>{userDate(context.to)}</p>
                <p>Before 11:00 AM</p>
            </div>
        </div>
        <br/>
        <div className='flex flex-even'>
            <span>
                <label>Adults:</label>&nbsp;
                <span>{context.adult}</span>
            </span>
            <span>
                <label>Children:</label>&nbsp;
                <span>{context.child}</span>
            </span>
        </div>
        <hr/>
        <div id="sum">
            <h5>{ratePlan["@attributes"].RatePlanName}</h5>
            <div>
                <div id="value">{formatDollar(nightlyRate)} / night</div>
                {!isInclusive?
                    <small>Excluding Taxes &amp; Fees</small>
                :   <small>Taxes Included</small>
                }
            </div>

            <div className="flex flex-even">
                <p>
                    Nights x {nights(context.to,context.from)}
                </p>
                <div className="amount">{formatDollar(data.totals["@attributes"].AmountBeforeTax)}</div>
            </div>
            {!isInclusive ?
                <>
                <div className="flex flex-even">
                    <p>Taxes & Fees</p>
                    <div className="amount">{formatDollar(parseFloat(data.totals.Taxes["@attributes"].Amount))}</div>
                </div>

                </>
            :""}
        </div>
        <hr/>
        <div id="total" className="flex flex-even br-10">
            Total:
            <div className="amount">{formatDollar(data.totals["@attributes"].AmountAfterTax)}</div>
        </div>
        
        {bookNow ?
            <button type='button' className="btn-book" onClick={()=>{context.handleRoomRateSelection(data,roomCode,data.allInfo['@attributes'].RatePlanCode,ratePlan,true)}}>
                    <NavLink to={`/room/${roomCode}/${data.allInfo['@attributes'].RatePlanCode}`}>Book Now</NavLink>
            </button>
        :""}

    </div>
    </>

  )
}

export default Summary
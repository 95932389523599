import Navbar from '../../components/Navbar/Navbar';
import RoomsContainer from '../../components/RoomsContainer/RoomsContainer';
import QueryModule from '../../components/QueryModule/QueryModule';
import HeroCol from '../../components/HeroCol/HeroCol';
import { AppContext } from '../../components/AppContext/AppContext';
import { useContext,useEffect } from 'react';
import { ModifySummary } from '../../components/ModifySummary/ModifySummary';
import { NavLink} from 'react-router-dom';


const Home = ({rooms,performSearch,params,handleDateRangeChange,handleDateGuestChange,searchMessage,handleRoomRateSelection,handleRateDetails,rateDetail,exitEditMode,prevRes,handlePreviousResDetails,sortRooms,year}) => {
  
  let hero = <HeroCol  data={rateDetail} handleRateDetails={handleRateDetails}/>;
  
  let context = useContext(AppContext);
  let header = "";
  let isModifying=false;

  useEffect(() => {
    // Update the document title using the browser API
    performSearch();
  },[]);

  // MODIFY MODE
  if(context.hasOwnProperty("modify")){
    isModifying=true;
     header = 
     <>
        <ModifySummary reservation={context.modify} exitEditMode={exitEditMode}/>
        <div className='flex flex-x-center'>
          {/* <button type='button' className='btn-main' onClick={()=>{handlePreviousResDetails(prevRes)}}>
              <NavLink to={`/room/${prevRes.roomCode}/${prevRes.code}`}>Keep previous dates, guests and rate</NavLink>
          </button> */}
        </div>
     </>
  }
  
  return (
    <>
    <div id="home" className={`${isModifying?"modify-mode":""} container no-pad`}>
      <div className='flex no-gap'>
        <div className='container br-5vh container-full'>
          <div id="head">
            <Navbar />
            {header}
            <QueryModule 
              params={params} 
              performSearch={performSearch} 
              handleDateRangeChange={handleDateRangeChange} 
              handleDateGuestChange={handleDateGuestChange}
              year={year}
            />
          </div>
          
          <RoomsContainer 
            rooms={rooms} 
            searchMessage={searchMessage} 
            handleRoomRateSelection={handleRoomRateSelection}
            handleRateDetails={handleRateDetails}
            rateDetail={rateDetail}
            sortRooms={sortRooms}
          />
        </div>
        
      </div>
    </div>

    </>
  )
}

export default Home;

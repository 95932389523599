import React from 'react'
import ReactDOM from 'react-dom';


export const Full = ({img,kill,slide,thumbnails}) => {
  return ReactDOM.createPortal(
    <div id="full" onClick={()=>{kill()}}>
        <div onClick={()=>{kill()}} id="close">✖</div>
        <div id="full-container" >
            <div onClick={(e)=>{e.stopPropagation();}} className="img-container">
                {img}
                {thumbnails}
            </div>
        </div>
        <button type="button" onClick={(e)=>{e.stopPropagation();slide(-1,true)}}>❮</button>
        <button type="button" onClick={(e)=>{e.stopPropagation();slide(1,true)}}>❯</button>
    </div>,
    document.getElementById('portal')
  )
}

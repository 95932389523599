import React from 'react'
import parse from 'html-react-parser';

const RateDescription = ({ratePlan}) => {
  console.log(ratePlan.RatePlanDescription.Text);
  return (
    <div id="rate-description">
      <div className='section'>
        
        <h4>{ratePlan.RatePlanDescription["@attributes"].Name}</h4>
        <p>{parse(String(ratePlan.RatePlanDescription.Text))}</p>
      </div>

      <div className='section'>
        <h4>Guarantee Policy</h4>
        <p>{ratePlan.Guarantee.GuaranteeDescription.Text}</p>
      </div>

      <div className='section'>
        <h4>Cancellation Policy</h4>
        <p>{ratePlan.CancelPenalties.CancelPenalty.PenaltyDescription.Text}</p>
      </div>

    </div>
  )
}

export default RateDescription
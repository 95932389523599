import React from 'react'

export const Footer = () => {
  return (
    <footer className='container'>
        <div className='flex flex-y-center'>
        <img alt="LT symbol" src='/images/lt_symbol.png' className='symbol' />
        <div>
            <p>
                    Las Terrazas Resort 3.5 Miles North Of San Pedro, Ambergris Caye, Belize <br/>
                    © {new Date().getFullYear()} Las Terrazas Resort &amp; Residences
            </p>
            <div className="flex" style={{"--gap":"10px"}}>
                    <a href="https://lasterrazasresort.com/belize-resort-privacy-policy/">Privacy Policy</a>
            </div>
        </div>
        </div>
    </footer>
  )
}

import Navbar from '../../components/Navbar/Navbar';
import { re,clearCookiesAndSession, CookieMonster, is_mobile } from '../../utilities/utilities';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import OrderDetails from '../../components/OrdeDetails/OrderDetails';




const Order = ({order}) => {
    // const [cancelPop, fireCancelPop] = useState("");
    // function kill() {
    //     fireCancelPop("");
    // }

    let navigate = useNavigate();
    function handleModify() {
        navigate("/");
    }
    let { success } = useParams();

    if(!order){
        // if(success === undefined){re("/");return;}
        // if(success === "success"){re("/");return;}
        // if(success === "found"){re("/find-booking");return;}
        re("/")
    }
    // let resJSON = booking.data.reservation;
    // let preReservation;
    
    // if(booking.type==="create"){
    //     preReservation = JSON.parse(resJSON).OTA_HotelResRS.HotelReservations.HotelReservation;
    // }else if(booking.type==="update"){
    //     preReservation = JSON.parse(resJSON).OTA_HotelResModifyRS.HotelResModifies.HotelResModify;
    // }
    // let reservation = PackReservation(preReservation).output;
    // let prevRes = PackPreviousResForModification(preReservation);

    let header="Your order has been processed successfully";
    let sub=<>
    <p>
        Thank you for choosing Las Terrazas Resort! We look forward to welcoming you soon.
    </p>
    <p>
        <u>You will receive a separate email confirming your purchase.</u>
    </p>
    </>;

    
    const monster = new CookieMonster();
    monster.eatAllCookies();

  return (
    <>
    {clearCookiesAndSession()}
    <div id="home" className='container no-pad'>
      <div className='flex no-gap col-body'>
        <div className='container br-10vh'>
          <div id="head">
            <Navbar />
          </div>
        
            <div className='pad-y-2'>
                <div className='flex flex-y-center flex-walls flex-break'>
                    <div id="booking-header">
                        <h1 className='br-10'>{header}</h1>
                        <div>{sub}</div>
                    </div>
                    
                </div>
            </div>
            <OrderDetails order={order}/>          
        </div>
        {!is_mobile()?
        <div className='hero-container'>
            <video className="hero-shot" mute="true" autoPlay={true} loop={true}>
                <source src="/images/01.mp4" type="video/mp4" />
            </video>
        </div>
        :""}
      </div>
    </div>

    </>
  )
}

export default Order;

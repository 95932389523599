import React from 'react'

const OrderDetails = ({order}) => {
    order = typeof order === "string" ? JSON.parse(order) : order ;
    let preCharges = typeof order.charges === "string" ? JSON.parse(order.charges) : order.charges ;
    console.log(preCharges);
    let charges = Object.keys(preCharges).map((c,i)=>{
            console.log(c);
        return <tr key={i}>
                <td>{preCharges[c]['charge']}:</td>
                <td>USD {preCharges[c]['value']}</td>
            </tr>;
    })
    console.log(charges);
    return (
    <div className='res-details'>
        <section>                            
        <h4 className=''>Your Order</h4>    
        <h5>Order Number: {order.order.order_id}</h5><br/>
                <div className='flex flex-even flex-resp-wrap'>
                    <div className='table-container'>                      
                        <table>
                            <tbody>
                                {charges}
                                <tr className='total'>
                                    <td>Total:</td>
                                    <td>USD {order.order.charge}</td>
                                </tr>
                            </tbody>
                        </table>                                               
                    </div>
                </div> 
                                
            </section>


            <section>
                <h4 className=''>Guest Information</h4>    
                <div className=''>
                    <div className='flex spec'>
                        <label>Contact Person:</label>
                        <div>{order.order.firstName} {order.order.lastName}</div>
                    </div>
                    <div className='flex spec'>
                        <label>Email:</label>
                        <div>{order.order.email}</div>
                    </div>
                    <div className='flex spec'>
                        <label>Address:</label>
                        <div>{order.order.address1}, {order.order.locality}, {order.order.administrativeArea}, {order.order.postalCode}, {order.order.country}</div>
                    </div>
                    <div className='spec'>
                        <label>Additional Details and Preferences:</label>
                        <div>{order.order.comments}</div>
                    </div>
                </div>
                
            </section>

      </div>
  )
}

export default OrderDetails